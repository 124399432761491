import React from 'react'
import { Link } from 'gatsby'
import DwMoveLink from '@/components/DwMoveLink'
import DwTag from '@/components/DwTag'
import sortByName from '@/utils/sortByName'
import generateDwPcClassPathById from '@/utils/generateDwPcClassPathById'

export default [
  {
    id: 'thief.backstory.innate_talent',
    name: 'Врождённый талант',
    text: (
      <>
        <p>Ты всё схватываешь на лету. Чтение и счёт. Прятки. Бросание камней. Скалолазание. Драки. Всё это у тебя хорошо получалось. Так же хорошо, как и у всех остальных, если не лучше.</p>
        <p>Конечно, у тебя репутация личности, нарушающей правила. Играешь ты грязно. Но зачем играть, если не для того, чтобы выиграть, верно? И к кому они обращаются, когда возникает проблема, требующая решения? К тебе, вот к кому.</p>
        <p><strong><DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink></strong>, ты можешь бросать +ИНТ вместо +МДР и добавить <em>«Какую возможность здесь больше никто не видит?»</em> в список доступных вопросов.</p>
      </>
    ),
  },
  {
    id: 'thief.backstory.operative',
    name: 'Оперативник',
    text: (
      <>
        <p>По зову долга или из-за необходимости, ты притворялся другими людьми чаще, чем был самим собой, а тёмная подноготная криминального мира знакома тебе не понаслышке.</p>
        <p><strong>Имея достаточно времени</strong>, ты можешь потратить <DwTag id='supplies'>Припасы</DwTag>, чтобы сделать маскировку. Твоя внешность может обмануть, но действия легко тебя выдадут, так что веди себя соответствующе.</p>
        <p><strong><DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink> или <DwMoveLink id='common.spout_lore'>Копаясь в памяти</DwMoveLink> о преступной деятельности</strong>, бросай с преимуществом.</p>
      </>
    ),
  },
  {
    id: 'thief.backstory.vagabond',
    name: 'Бродяга',
    text: (
      <>
        <p>Ты провёл значительную часть своей жизни, далеко путешествуя и живя своим умом. Почему? Какими поступками ты хвастаешься, а о каких сожалеешь?</p>
        <p>Теперь ты в некотором роде знаменитость, и у тебя есть друзья (или достаточно близкие знакомые), разбросанные по всему известному миру.</p>
        <p><strong>Когда ты заявляешь, что у тебя есть контакт в области</strong> — кто-то, кто может помочь — назови эту личность и брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— эта личность может помочь безо всяких проблем. Расскажи — почему она готова это сделать?</li>
          <li>На <strong>7–9</strong>&nbsp;— эта личность может помочь, но выбери одно.</li>
          <li>
            <p>На <strong>6−</strong>&nbsp;— эта личность может помочь, но выбери одно и Ведущий выберет ещё одно.</p>
            <ul>
              <li>Эта личность всё ещё держит на тебя обиду.</li>
              <li>Этой личности сначала понадобится что-то от тебя.</li>
              <li>Эта личность очень давно поклялась что не будет делать подобного.</li>
              <li>Ты не то чтобы можешь сильно доверять этой личности.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.start.nimble_fingers',
    name: 'Ловкие пальцы',
    text: (
      <>
        <p><strong>Выполняя трюк ловкости рук на ничего не подозревающей жертве</strong>, у тебя получается и никто ничего не замечает. Если за тобой наблюдают, брось +ЛОВ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— у тебя получается и никто ничего не заметил.</li>
          <li>На <strong>7–9</strong>&nbsp;— у тебя получается ИЛИ никто ничего не заметил (на твой выбор).</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.cat_s_grace',
    name: 'Кошачья грация',
    text: (
      <>
        <p><strong>Имея <Link to={generateDwPcClassPathById('thief', 'capacity')}><em>лёгкую</em> нагрузку</Link> и двигаясь осторожно</strong>, ты не создаёшь шума.</p>
        <p><strong>Стоя неподвижно в тенях или темноте</strong>, тебя нельзя обнаружить ничем меньшим, чем сверхъестественным чувством или магией, пока ты не сделаешь что-то, что тебя обнаружит (например, атакуешь).</p>
      </>
    ),
  },
  {
    id: 'thief.advanced.light_feet',
    name: 'Лёгкие ноги',
    text: (
      <p><strong>Двигаясь с <Link to={generateDwPcClassPathById('thief', 'capacity')}><em>лёгкой</em> нагрузкой</Link> споро и проворно</strong>, ты получаешь преимущество на любые ходы для преодоления или избегания физического препятствия.</p>
    ),
  },
  {
    id: 'thief.advanced.special_technic',
    name: 'Особая техника',
    text: (
      <p><strong>Орудуя одноручным оружием изящно и быстро</strong>, ты можешь считать его <DwTag id='precise'>Точным</DwTag>.</p>
    ),
  },
  {
    id: 'thief.advanced.sense_of_danger',
    name: 'Чувство опасности',
    text: (
      <>
        <p><strong>Ты всегда можешь спросить Ведущего</strong> <em>«Здесь есть засада или ловушка?»</em>. Если ответ <em>«Да»</em>, брось +ИНТ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— задай Ведущему оба вопроса из списка ниже и получи преимущество на следующий ход, действуя на основе ответа.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— то же самое, но задай только один вопрос.</p>
            <ul>
              <li>Что здесь приведёт в действие ловушку или засаду?</li>
              <li>Что произойдёт после того, как сработает ловушка или случится засада?</li>
            </ul>
          </li>
          <li>На <strong>6−</strong>&nbsp;— не отмечай опыт. Ты знаешь что здесь есть засада или ловушка, но ничего плохого пока что не происходит.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.ambush',
    name: 'Засада',
    text: (
      <>
        <p><strong>Нападая в ближнем бою на кого-то, кто этого не ожидает</strong>, ты можешь выбрать: нанести урон или рискнуть. <strong>Если ты рискуешь</strong>, брось +ЛОВ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— нанеси урон и выбери два.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— нанеси урон и выбери одно.</p>
            <ul>
              <li>Нанести жертве +1к4 урона.</li>
              <li>Ты не даёшь жертве издать шум / поднять тревогу</li>
              <li>Ты ускользаешь до того, как жертва успеет среагировать.</li>
              <li>Ты создаёшь возможность: ты или союзник получают преимущество на следующий ход, если ей воспользуются.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.perceptive',
    name: 'Внимательность',
    text: (
      <p><strong><DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink></strong>, ты можешь задать дополнительный вопрос из списка. Даже на 6− ты можешь задать 1 вопрос (но ответ тебе, скорее всего, не понравится).</p>
    ),
  },
  {
    id: 'thief.advanced.robbery',
    name: 'Ограбление',
    text: (
      <>
        <p><strong>Вламываясь куда-то (в особняк, сейф, сундук и т. п.).</strong>, брось +ИНТ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— ты попадаешь туда, но выбери одно.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— ты попадаешь туда, но выбери два.</p>
            <ul>
              <li>Это потребовало много усилий и времени.</li>
              <li>Ты создаёшь много шума.</li>
              <li>В процессе ты что-то ломаешь.</li>
              <li>Ты тратишь 1к3 <DwTag id='supplies'>Припасов</DwTag>. Опиши — чего именно?</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.vigilance',
    name: 'Бдительность',
    text: (
      <p><strong>Если тебя должны застать врасплох</strong>, этого не происходит, и ты действуешь первым. Если Ведущий описывает ситуацию, в которой тебя застали врасплох, даже на 6−, напомни ему про этот ход.</p>
    ),
  },
  {
    id: 'thief.advanced.lets_dance',
    name: 'Давай потанцуем',
    text: (
      <p><strong>Орудуя <DwTag id='precise'>Точным</DwTag> оружием</strong>, ты получаешь те же бонусы, как если бы носил щит (+1 брони).</p>
    ),
  },
  {
    id: 'thief.advanced.incredible_reflexes',
    name: 'Невероятные рефлексы',
    text: (
      <p><strong>Если ты не носишь броню и имеешь <Link to={generateDwPcClassPathById('thief', 'capacity')}><em>лёгкую</em></Link> или <Link to={generateDwPcClassPathById('thief', 'capacity')}><em>нормальную</em></Link> нагрузку</strong>, то любой урон по тебе, от которого ты можешь увернуться или перекатиться, получает помеху.</p>
    ),
  },
  {
    id: 'thief.advanced.dirty_tricks',
    name: 'Грязные приёмы',
    text: (
      <>
        <p><em>Требуется</em>: <DwMoveLink id='thief.advanced.ambush'>Засада</DwMoveLink></p>
        <p>Добавь эти варианты в ход <DwMoveLink id='thief.advanced.ambush'>Засада</DwMoveLink>:</p>
        <ul>
          <li>Твоя атака игнорирует броню жертвы.</li>
          <li>Ты забираешь что-то, что было у жертвы — теперь это твоё.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.ace_in_the_hole',
    name: 'Туз в рукаве',
    text: (
      <>
        <p><strong>Если ты прячешь на себе <DwTag id='subtle'>Мелкий</DwTag> предмет</strong>, то его нельзя найти. Так можно спрятать ЛОВ предметов (минимум 1).</p>
        <p><strong>Потянувшись в скрытые ножны за оружием</strong>, ты достаёшь оттуда нож, сюрикен или подобное <DwTag id='subtle'>Мелкое</DwTag> оружие. После этого ты не можешь вытащить таким образом ещё одно такое оружие, пока у тебя не появится <strong>свободное время в поселении</strong>. </p>
        <p>Обнаружить такие скрытые ножны и предметы можно только если тебя одновременно связали, раздели догола и подробнейшим образом обыскали (или ты сам захочешь, чтобы их нашли).</p>
      </>
    ),
  },
  {
    id: 'thief.advanced.flexible_morale',
    name: 'Гибкая мораль',
    text: (
      <p>Выбери ещё одно <Link to={generateDwPcClassPathById('thief', 'strives')}>Стремление</Link>. <strong>В <DwMoveLink id='common.session_end'>Конце сессии</DwMoveLink></strong>, ты можешь отмечать опыт дважды если выполнил два разных <Link to={generateDwPcClassPathById('thief', 'strives')}>Стремления</Link>.</p>
    ),
  },
  {
    id: 'thief.advanced.bullseye_technic',
    name: 'Техника Меченого',
    text: (
      <>
        <p><strong>В твоих руках ножи, кинжалы и любые подобные острые предметы</strong> (гвоздь, осколок стекла, заострённая морковка и т. п.) считаются <DwTag id='thrown'>Метательными</DwTag>.</p>
        <p><DwMoveLink id='common.volley'>Давая залп</DwMoveLink> <DwTag id='thrown'>Метательным</DwTag> оружием, ты не можешь промахнуться.</p>
      </>
    ),
  },
  {
    id: 'thief.advanced.get_under_skin',
    name: 'Залезть под кожу',
    text: (
      <>
        <p><strong>Общаясь с персонажем Ведущего</strong>, ты можешь задать Ведущему один вопрос из списка и получить честный ответ:</p>
        <ul>
          <li>Каких действий он ожидает от меня?</li>
          <li>Что он, в целом, пытается скрыть?</li>
          <li>Что он хочет, чтобы произошло?</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.casuistry',
    name: 'Казуистика',
    text: (
      <>
        <p><strong>Используя слова, чтобы избежать подозрений или проблем</strong>, брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— получи 3 <em>Наглости</em> (максимум 3).</li>
          <li>На <strong>7–9</strong>&nbsp;— получи 1 <em>Наглости</em>.</li>
        </ul>
        <p>Ты можешь тратить <em>Наглость</em> 1 к 1, чтобы:</p>
        <ul>
          <li>Беспрепятственно передвигаться или маневрировать.</li>
          <li>Выдержать прямой допрос или пристальное внимание.</li>
          <li>Перенаправить подозрения или внимание куда-то ещё.</li>
          <li>Прикрыть союзника.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.advanced.poisoner',
    name: 'Отравитель',
    text: (
      <>
        <p><strong>Имея время и безопасное место</strong>, ты можешь создать дозу яда. Расскажи что делает этот яд, и Ведущий выберет один или несколько вариантов:</p>
        <ul>
          <li>Тебе придётся потратить дополнительное время или средства.</li>
          <li>Он будет иметь некоторые ограничения на его использование (должен быть проглочен; нестабилен и должен быть использован сразу же; нужна большая доза и т. п.).</li>
          <li>Он не так силён, как хотелось бы.</li>
          <li>Эффект яда проявится не сразу.</li>
          <li>Имеет очевидные или нежелательные побочные эффекты.</li>
        </ul>
        <p>Чтобы ни выбрал Ведущий, все яды <DwTag id='dangerous'>Опасны</DwTag> в обращении.</p>
      </>
    ),
  },
  {
    id: 'thief.advanced.thief_arsenal',
    name: 'Воровской арсенал',
    text: (
      <>
        <p>Ты знаешь, где и как достать особые инструменты для воровских дел. <strong>Копаясь в <DwTag id='supplies'>Припасах</DwTag>, чтобы найти особую стрелу или болт</strong>, потрать <DwTag id='supplies'>Припасы</DwTag> и достань оттуда…</p>
        <ul>
          <li><strong>Бронебойный снаряд</strong>&nbsp;— выстрел получает <DwTag id='piercing'>Пробивание 2</DwTag>.</li>
          <li><strong>Снаряд с верёвкой</strong>&nbsp;— протягивает к тебе верёвку от точки попадания в радиусе <DwTag id='near'>Близко</DwTag>.</li>
          <li><strong>Шумный снаряд</strong>&nbsp;— создаёт громкий звук в месте попадания.</li>
          <li><strong>Дымный снаряд</strong>&nbsp;— создаёт небольшое облако чёрного, непроглядного, вызывающего кашель, дыма.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.capstone.kidney_blow',
    name: 'Удар по почкам',
    text: (
      <>
        <p><em>Требуется</em>: <DwMoveLink id='thief.advanced.dirty_tricks'>Грязные приёмы</DwMoveLink></p>
        <p><strong>Захватывая врага в <DwMoveLink id='thief.advanced.ambush'>Засаду</DwMoveLink> оружием со свойствами <DwTag id='hand'>Рука</DwTag> или <DwTag id='precise'>Точное</DwTag></strong>, бросай урон с преимуществом.</p>
      </>
    ),
  },
  {
    id: 'thief.capstone.eyes_at_door',
    name: 'Глаза на двери',
    text: (
      <>
        <p><strong>Когда пора делать ноги</strong>, опиши свой путь побега и брось +ИНТ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— ты сбегаешь.</li>
          <li>На <strong>7–9</strong>&nbsp;— ты можешь остаться или сбежать, но если ты сбегаешь, то Ведущий скажет — что (или кого) ты оставил позади или захватил с собой.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'thief.capstone.pants_on_fire',
    name: 'На воре шапка горит',
    text: (
      <>
        <p><strong><DwMoveLink id='common.defy_danger'>Спасаясь от угрозы</DwMoveLink> или <DwMoveLink id='common.parley'>Договариваясь</DwMoveLink> с помощью обмана или лжи</strong>, бросай с преимуществом.</p>
        <p>Когда другой ход (например, <DwMoveLink id='common.discern_realities'>Изучить обстановку</DwMoveLink>) позволяет другому игроку задать тебе вопрос, ты можешь не отвечать.</p>
      </>
    ),
  },
  {
    id: 'thief.capstone.slick',
    name: 'Скользкий',
    text: (
      <>
        <p><strong>Когда тебя схватили или взяли под контроль и ты делаешь ход, чтобы сбежать</strong>, считай 6− как 7–9.</p>
        <p><strong>На 12+</strong> опиши, как ты изменяешь ситуацию или используешь обстоятельства в свою пользу.</p>
      </>
    ),
  },
  {
    id: 'thief.capstone.tricky_blow',
    name: 'Коварный удар',
    text: (
      <>
        <p><em>Требуются</em>: <DwMoveLink id='thief.advanced.ambush'>Засада</DwMoveLink> и <DwMoveLink id='thief.advanced.special_technic'>Особая техника</DwMoveLink>.</p>
        <p><strong><DwMoveLink id='common.defend'>Вставая на защиту</DwMoveLink> с <DwTag id='precise'>Точным</DwTag> оружием, и тратя 1 <em>Готовность</em> на то, чтобы ударить атакующего в ответ</strong>, также выбери один вариант из списка хода <DwMoveLink id='thief.advanced.ambush'>Засада</DwMoveLink>.</p>
      </>
    ),
  },
]
  .map(
    move => ({
      ...move,
      classId: 'thief',
    }),
  )
  .sort(sortByName)
