import React from 'react'

import DwMoveLink from '@/components/DwMoveLink'
import sortByName from '@/utils/sortByName'
import generateDwPcClassPathById from '@/utils/generateDwPcClassPathById'
import { Link } from 'gatsby'

export default [
  {
    id: 'warlock.background.cultist',
    name: 'Культист',
    text: (
      <>
        <p>Твой Покровитель поддерживается мистическим культом. Опиши их и где они обычно находятся, затем опиши по одному даваемому ими преимуществу и осложнению.</p>
        <ul>
          <li>
            <p><strong>Преимущество культа</strong>: ______________________.</p>
          </li>
          <li>
            <p><strong>Осложнение культа</strong>: ______________________.</p>
          </li>
        </ul>
        <p><strong>Ища последователей своего Покровителя в месте, где их логично было бы найти</strong>, ты обычно находишь кого-то, кто готов предложить тебе помощь от имени твоего Покровителя.</p>
      </>
    ),
  },
  {
    id: 'warlock.background.seeker',
    name: 'Искатель',
    text: (
      <>
        <p>В своих поисках тайного знания ты открыл То, Что Человеку Не Положено Знать.</p>
        <p><strong>Обращаясь к своим знаниям о магии, чтобы создать магический эффект</strong>, брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— ты создаёшь эффект из списка ниже.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— то же, что и на 10+, но ты получаешь <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink>.</p>
            <ul>
              <li>Вещь, которой ты касаешься, начинает светиться магическим светом с яркостью факела и продолжает светиться, пока ты не развеешь эффект или она не окажется далеко.</li>
              <li>Ведущий скажет, что вокруг есть магического.</li>
              <li>Ты вносишь косметические изменения в предмет, к которому прикасаешься: очищаешь его, пачкаешь, охлаждаешь, нагреваешь, меняешь его запах или цвет.</li>
              <li>Ты восстанавливаешь одну трещину или разрыв в предмете, который держишь.</li>
              <li>Ты медленно телекинетически поднимаешь или перемещаешь видимый тобой лёгкий предмет, который никто не несёт и не носит.</li>
              <li>Ты ненадолго создаёшь безвредный эффект, вроде снопа искр из пальцев, лёгкого порыва ветра, тихой мелодии и т. п.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.background.chosen_one',
    name: 'Избранный',
    text: (
      <>
        <p>Твой Покровитель избрал тебя своим глашатаем в этом мире, возможно потому, что ты искал его или тебя предлагали ему в качестве жертвоприношения или ещё по каким-то непостижимым причинам.</p>
        <p><strong>Один раз в сессию, когда ты должен получить <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink></strong>, ты можешь решить не получать его.</p>
      </>
    ),
  },
  {
    id: 'warlock.start.contract',
    name: 'Договор',
    text: (
      <>
        <p>Ты заключил договор с могущественным существом из-за пределов твоей реальности, которое желает бо́льшего влияния в твоём мире. <strong>Выбери Покровителя из списка и дай ему имя</strong>, или придумай своего вместе с Ведущим:</p>
        <ul>
          <li><strong>Дракон</strong>. Желает: сокровищ, редкостей, почитания.</li>
          <li><strong>Древний Бог</strong>. Желает: мести, монументов, почитателей.</li>
          <li><strong>Исчадие</strong>. Желает: душ, богатства, разрушения.</li>
          <li><strong>Машина</strong>. Желает: порядка, прогресса, совершенства.</li>
          <li><strong>Природа</strong>. Желает: биомассы, культивации жизни, новых земель.</li>
          <li><strong>Пришелец</strong>. Желает: секретов, странных вещей, умов.</li>
          <li><strong>Пустота</strong>. Желает: энтропии, безудержного хаоса, всего.</li>
          <li><strong>Свет </strong>. Желает: воздаяния, правды, очищения порчи.</li>
          <li><strong>Фея</strong>. Желает: красивых вещей, обещаний, веселья.</li>
          <li><strong>______________</strong>. Желает: ________________________________________________________</li>
        </ul>
        <p>Затем заполни следующие пробелы:</p>
        <blockquote>
          <p><em>«Мой Покровитель ______________, властвующий над ______________, известен среди смертных как ______________. Он появляется в моих видениях как ______________, и отметил меня ______________. Он желает ______________, и я ему в этом помогаю.»</em></p>
        </blockquote>
        <p><strong>Когда ты <DwMoveLink id='common.discern_realities'>Изучаешь обстановку</DwMoveLink></strong>, добавь следующий вопрос в список:</p>
        <ul>
          <li>Чем здесь я могу услужить Покровителю?</li>
        </ul>
        <p><strong>Когда ты исполняешь желание Покровителя</strong>, потеряй весь <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink>.</p>
      </>
    ),
  },
  {
    id: 'warlock.start.hex',
    name: 'Сглаз',
    text: (
      <>
        <p><strong>Черпая силу Покровителя, чтобы ослабить врагов</strong>, назови жертву (которую ты либо видишь, либо имеешь при себе её часть), и брось +ИНТ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— выбери одно и опиши, как сила Покровителя проявляет себя в мире.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— то же самое, но получи 1 <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink>.</p>
            <ul>
              <li><strong>Агония</strong>: нанеси жертве урон с одним из этих свойств: <em>Мощный</em>, <em>Незаметный</em>, <em>Пробивание брони +ХАР</em>.</li>
              <li><strong>Неудача:</strong> жертва страдает от внезапного момента неудачи, спотыкаясь или роняя что-то, что несёт.</li>
              <li><strong>Сон</strong>: жертва наполняется апатией, впадая в лёгкий сон, если вокруг нечему её потревожить или пробудить.</li>
              <li><strong>Ужас</strong>: жертва наполняется сверхъестественным ужасом и цепенеет на несколько мгновений.</li>
              <li><strong>Цепи</strong>: жертва ненадолго не может сдвинуться с места.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.start.servant',
    name: 'Должник',
    text: (
      <>
        <p><strong>Твой максимум <em>Долга</em></strong> равен твоей ХАР.</p>
        <p>Когда на <strong>любой ход Колдуна у тебя выпадает 6− и у тебя есть <em>Долг</em></strong>, Ведущий может потратить 1 твой <em>Долг</em>. Выбери одно из списка и опиши эффект.</p>
        <p><strong>Если у тебя уже максимум <em>Долга</em> и ты должен получить ещё <em>Долг</em></strong>, не получай <em>Долг</em>, сразу выбери один вариант из списка, и опиши эффект:</p>
        <ul>
          <li>Порча просачивается из и вокруг тебя. Кто-то или что-то поблизости испорчено, искажено или развращено.</li>
          <li>Покровитель ненадолго захватывает контроль над твоим телом. Этого достаточно, чтобы совершить через тебя какое-нибудь действие.</li>
          <li>Мощь Покровителя переполняет и оглушает тебя на несколько мгновений. Покровитель также может послать тебе сообщение и выразить тебе своё недовольство или желания.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.start.dark_rite',
    name: 'Тёмный обряд',
    text: (
      <>
        <p><strong>Подготавливая обряд, чтобы о чём-то попросить Покровителя</strong>, опиши Ведущему — чего ты хочешь добиться и каким образом. Ведущий поставит перед тобой от 1 до 4 требований из списка ниже. Когда ты выполнишь эти требования, проведи обряд и Покровитель выполнит твою просьбу.</p>
        <ul>
          <li>Это займёт несколько минут / часов / дней / недель / месяцев.</li>
          <li>Ты должен сделать это в месте, важном для Покровителя (например _____________).</li>
          <li>Ты должен сделать это в определённое время (например _____________).</li>
          <li>Сначала ты должен _____________</li>
          <li>Тебе нужна помощь от ___________</li>
          <li>Ты сможешь добиться лишь _____________</li>
          <li>Вы с союзниками рискуете из-за _____________</li>
          <li>Тебе нужно пожертвовать или потратить ____________, чтобы сделать это.</li>
        </ul>
      </>
    ),
    quote: {
      text: 'Мои планы начинают осуществляться',
      author: 'Гул’Дан',
    },
  },
  {
    id: 'warlock.advanced.snake_cunning',
    name: 'Змеиное коварство',
    text: (
      <>
        <p><strong><DwMoveLink id='common.parley'>Договариваясь</DwMoveLink> с кем-то или <DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink> о ком-то</strong>, <strong>на 10</strong>+ ты также узнаёшь что-то об его настоящей природе или чего он больше всего хочет — Ведущий скажет что.</p>
        <p><strong>Действуя на основе этой информации</strong>, ты получаешь преимущество на следующий ход.</p>
      </>
    ),
  },
  {
    id: 'warlock.advanced.sheep_clothing_wolf',
    name: 'Волк в овечьей шкуре',
    text: (
      <>
        <p><strong>Покуда ты не используешь свои силы</strong>, твоя истинная природа скрыта. Любой, кто попытается изучить тебя, физически или магически, посчитает что ты обычный безвредный обыватель, и даже отметина Покровителя не предстанет чем-то, привлекающим внимание. Если кто-то пытается понять твою истинную сущность, даже незаметно для тебя, ты об этом узнаешь (опиши, как именно&nbsp;— тебе сообщает об этом бестелесный шёпот и т. п.).</p>
        <p>Когда кто-то пытается понять твою истинную сущность, ты можешь сделать так чтобы он решил, что ты — иная сущность (маг, жрец, оборотень и т. п.). <strong>Пытаясь предстать сущностью другой природы</strong>, опиши — кем или чем ты хочешь предстать и брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— ты успешно обманываешь проверяющего.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— выбери одно.</p>
            <ul>
              <li>Получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink> и всё равно успешно выдай себя за что-то другое.</li>
              <li>Твой обман удаётся, но лишь ненадолго. Скоро проверяющий начнёт подозревать, что в тебя что-то было не так.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.advanced.stars_in_line',
    name: 'Звёзды встали в ряд',
    text: (
      <>
        <p><strong>Проводя сложный ритуал, чтобы вызвать из-за Завесы одного из слуг твоего Покровителя</strong>, назови — кого ты хочешь призвать, для чего, затем получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink> и бросьте +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— выбери одно.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— выбери два.</p>
            <ul>
              <li>Ты не можешь контролировать вызванного слугу.</li>
              <li>У слуги есть своя мотивация или он попытается исказить твой замысел.</li>
              <li>Он нанесёт побочный ущерб, выполняя твой замысел.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.advanced.ancient_sign',
    name: 'Древние знаки',
    text: (
      <>
        <p><strong>Изгоняя существо с другого плана или мира с помощью  волшебных слов и знаков</strong>, брось +ИНТ.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— оно должно вернуться туда, откуда пришло, и Ведущий скажет тебе одну вещь, которую ты можешь сделать, чтобы оно больше никогда не вернулось на этот план.</li>
          <li>На <strong>7–9</strong>&nbsp;— оно не может к тебе приблизиться, пока ты ему не позволишь (или пока ты не потеряешь бдительность или отвлечёшься).</li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.advanced.forbidden_knowledge',
    name: 'Запретное знание',
    text: (
      <>
        <p><strong>Ритуально моля Покровителя о знании</strong>, ты получишь от него знак: задайте интересующий тебя вопрос брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— выбери одно.</li>
          <li>На <strong>7–9</strong>&nbsp;— выбери два.</li>
          <li>
            <p>На <strong>6−</strong>&nbsp;— выбери все три.</p>
            <ul>
              <li>Видение нечёткое и полно символизма.</li>
              <li>Ответ искажён так, чтобы служить воле Покровителя.</li>
              <li>Ты получаешь <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink>.</li>
            </ul>
          </li>
        </ul>
        <p>Ведущий опишет видение, отвечая на вопрос так чётко, как позволяет полученный знак.</p>
      </>
    ),
  },
  {
    id: 'warlock.advanced.bewitch',
    name: 'Околдовать',
    text: (
      <>
        <p><strong>Смотря кому-то глубоко в глаза</strong>, брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— выбери три.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink> и выбери два.</p>
            <ul>
              <li>Он относится к тебе как к другу, пока ты не докажешь обратное.</li>
              <li>Он бежит в ужасе.</li>
              <li>Он даёт тебе то, что держит.</li>
              <li>Он правдиво отвечает на один вопрос.</li>
              <li>Он забывает, что ты вообще здесь был.</li>
              <li>Его разум не повреждён контактом.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.advanced.spill_blood',
    name: 'Проливай кровь в мою честь',
    text: (
      <>
        <p><strong>Покровитель предоставляет тебе оружие ближнего боя на твой выбор</strong>&nbsp;— это любое обычное оружие, но его внешний вид отмечен твоим Покровителем. Опиши: как именно? Это оружие исчезает, если окажется далеко от тебя дольше чем на несколько минут.</p>
        <p><strong><DwMoveLink id='common.hack_n_slash'>Рубя и кромсая</DwMoveLink> этим оружием</strong>, бросай +ХАР вместо +СИЛ.</p>
        <p><strong>Несколько минут ритуально поклоняясь Покровителю</strong>, получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink> и выбери одно:</p>
        <ul>
          <li>Новое оружие материализуется у тебя в руках (а старое исчезает).</li>
          <li>Ты изменяешь форму существующего оружия (к примеру, меч становится булавой).</li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.advanced.blue_black',
    name: 'Иссиня чёрное',
    text: (
      <>
        <p><strong>Когда ты произносишь тёмные слова, чтобы получить часть силы Покровителя</strong>, твои руки и оружие пронизывает его мощь (опиши, как это выглядит). Они получают свойство <em>Месиво</em> и твоя кость урона становится к8.</p>
        <p>Эффект длится, пока ты не прекратишь его сам или пока не <DwMoveLink id='common.set_camp'>Разобьёшь лагерь</DwMoveLink>. <strong>Когда эффект прекращается, а ты никого не убил </strong>, получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink>.</p>
      </>
    ),
  },
  {
    id: 'warlock.advanced.omen',
    name: 'Предзнаменование',
    text: (
      <>
        <p><strong>Проснувшись после ночного сна</strong>, ты получаешь смутное видение от Покровителя о том, что тебя ждёт в наступающем дне. Получи 1 <em>Пророчество</em> (максимум 1).</p>
        <p>Ты можешь потратить <em>Пророчество</em>, чтобы автоматически получить 10+ на <DwMoveLink id='common.defy_danger'>Спасение от угрозы</DwMoveLink>. Опиши, как видение помогло тебе.</p>
      </>
    ),
  },
  {
    id: 'warlock.capstone.unlimited_devotion',
    name: 'Безграничная преданность',
    text: (
      <>
        <p><strong>Накладывая на кого-то <DwMoveLink id='warlock.start.hex'>Сглаз</DwMoveLink></strong>, на 12+ твоя порча срабатывает лучше, чем ожидалось или имеет какую-то полезную особенность. Ведущий опишет — какую.</p>
        <p><strong>Проводя <DwMoveLink id='warlock.start.dark_rite'>Тёмный обряд</DwMoveLink></strong>, ты можешь игнорировать одно требование Ведущего.</p>
      </>
    ),
  },
  {
    id: 'warlock.capstone.blade_of_worthy',
    name: 'Клинок достойного',
    text: (
      <>
        <p><em>Требуется</em>: <DwMoveLink id='warlock.advanced.spill_blood'>Проливай кровь в мою честь</DwMoveLink></p>
        <p><strong>Получая предоставленное тебе оружие</strong> (или когда ты получаешь этот ход), выбери его особенность:</p>
        <ul>
          <li>Оно может вредить нематериальным существам, как если бы они были материальными.</li>
          <li>По твоему желанию оно как исчезает, так и материализуется в твоей руке.</li>
          <li>Оно получает <em>Пробивание брони ХАР</em>.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.capstone.curse',
    name: 'Проклятье',
    text: (
      <>
        <p><strong>Добавь эти варианты в список <DwMoveLink id='warlock.start.hex'>Сглаза</DwMoveLink></strong>:</p>
        <ul>
          <li><strong>Предательство</strong>: жертва ненадолго начинает считать своих союзников врагами.</li>
          <li><strong>Тьма</strong>: жертва не может тебя видеть.</li>
          <li><strong>Истина</strong>: жертва не может скрывать от тебя правду или свои намерения.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'warlock.capstone.door_to_nowhere',
    name: 'Дверь в никуда',
    text: (
      <>
        <p><strong>Открывая в пространстве разлом, ведущий к твоему Покровителю</strong>, получи <DwMoveLink id='warlock.start.servant'>Долг</DwMoveLink> и брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— мощь и влияние Покровителя ненадолго просачивается в реальность вокруг разлома (опиши — как это выглядит?), нанося 2к6 игнорирующего броню урона всем твоим врагам в радиусе <em>Близко</em>, и отмечая вещи и область вокруг своим влиянием.</li>
          <li>На <strong>7–9</strong>&nbsp;— то же самое, но мощь Покровителя воздействует на всех без разбора.</li>
        </ul>
      </>
    ),
    quote: {
      text: `Прости, старина. Похоже, это я привёл орков в этот мир. Скверна изменила меня. Даже не знаю, что ещё я мог натворить. Я просто… ничего не помню. Всё, что поклялся защищать, сам же и уничтожил. Я не могу сдержать скверну. Никто не может.`,
      author: 'Медив',
    },
  },
]
  .map(
    move => ({
      ...move,
      classId: 'warlock',
    }),
  )
  .sort(sortByName)
