const React = require('react')

module.exports = {
  id: 'ghost',
  name: 'Призрак',
  description: [
    ``,
  ],
  nameList: [''],
  featureList: [
    {
      name: 'Взгляд',
      variantList: ['', ''],
    },
    {
      name: 'Причёска',
      variantList: ['', ''],
    },
    {
      name: 'Одежда',
      variantList: ['', ''],
    },
    {
      name: 'Тело',
      variantList: ['', '', ''],
    },
  ],
  hpBonus: 0,
  damageDice: 0,
  striveList: [
    {
      name: '',
      text: '',
    },
  ],
  backgroundIdList: [
    '',
  ],
  startMoveIdList: [
    '',
  ],
  advancedMoveIdList: [
    '',
  ],
  capstoneMoveIdList: [
    '',
  ],
  specialMoves: {
    title: '',
    list: [
      '',
    ]
  },
}

const foo = `


<p><strong>Когда вы умираете, но ваша душа остаётся, </strong>отказываясь пересечь Чёрные Врата, замените своё
    Стремление, выберите свою Ужасную цель, получите стартовые ходы и сразу выберите 1 Последствие (см. ниже).</p>

<h1>Стремление мертвеца</h1>
<p>Вы теряете все свои Стремления и заменяете его одним из нижеперечисленных:</p>
<h3><strong>Отрицание</strong></h3>
<p>Откажитесь принять тот факт что вы умерли.</p>
<h3><strong>Одержимость</strong></h3>
<p>Преследовать свою Ужасную цель несмотря ни на что.</p>
<h3><strong>Одержимость</strong></h3>
<p>Причиняйте неприятности своим союзникам, преследуя свою Ужасную цель.</p>

<h1>Ужасная цель</h1><h2>Выберите одно:</h2>

<h3><strong>Привязанность</strong></h3>
<p><strong>Назовите</strong> человека или группу которых вы отказались отпускать.</p>
<ul>
    <li><strong>Проводя ночь наблюдая за ними,</strong>&nbsp;восстановите все свои ОЗ или избавьтесь от всех Травм.</li>
    <li>Когда они<strong>&nbsp;дают вам отпор или отшатываются от вас</strong>, выберите&nbsp;Последствие.</li>
    <li>Когда они&nbsp;<strong>мирно умирают и проходят через Чёрные Врата</strong>, также делаете и вы.</li>
    <li>Если их&nbsp;<strong>забирают у вас силой</strong>, отметьте&nbsp;Финальное последствие.</li>
</ul>

<h3><strong>Месть</strong></h3>
<p>Назовите человека или группу&nbsp;которые должны заплатить.</p>
<ul>
    <li><strong>Заставляя одного из них заплатить и давая им понять за что</strong>, восстановите все ОЗ или избавьтесь
        от всех Травм.
    </li>
    <li>Когда они<strong>&nbsp;побеждают вас или сбегают от вас</strong>,&nbsp;выберите&nbsp;Последствие.</li>
    <li>Когда вы&nbsp;<strong>убиваете последнего из них</strong>, вы проходите через Чёрные Врата.</li>
    <li>Если они&nbsp;<strong>умрут до того как вы с ними закончили</strong>, отметьте&nbsp;<span><span>Финальное последствие.</span></span>
    </li>
</ul>

<h3><strong>Дело</strong></h3>
<p>Назовите дело которые вы отказались оставлять незаконченным.</p>
<ul>
    <li><strong>Проводя всю ночь работая над своим делом или достигая важного этапа,</strong>&nbsp;восстановите все свои
        ОЗ или избавьтесь от всех Травм.
    </li>
    <li><strong>Если вы не справляетесь со своей задачей или терпите существенную неудачу</strong>, выберите&nbsp;Последствие.
    </li>
    <li>Когда вы&nbsp;<strong>заканчиваете своё дело</strong>, вы проходите через Чёрные Врата.</li>
    <li><strong>Если дело станет невыполнимым</strong>, отметьте&nbsp;Финальное последствие.</li>
</ul>

<h2>Вы получаете все эти ходы:</h2>

<h3><strong>Не-жизнь</strong></h3>
<p>Вы не дышите. Вам не нужно есть, пить и спать. Вы не лечитесь обычными методами. Вы не получаете никакой пользы от
    магического исцеления, ходов Разбить лагерь, Восстановиться и подобных.</p>

<h3><strong>Бестелесный</strong></h3>
<p>Ваше тело мертво и осталось позади, но вы продолжаете существовать в виде нематериального духа. Вам можно навредить
    только методами, способными навредить духам или призракам и вы&nbsp;можете лишь незначительно влиять на материальный
    мир.</p>
<p>Обычно вы невидимы, а материальный мир для вас тусклый и искажённый. <strong>Проявляя своё призрачное присутствие в
    тенях или темноте</strong>, мир возвратит свои очертания. Затем вы можете выбрать столько пунктов, сколько хотите,
    но за каждый выбранный потеряйте ещё 1к4 ОЗ:</p>
<ul>
    <li>Вы проявляетесь чётко и ясно, как если бы вы были живы</li>
    <li>Вы можете&nbsp;ясно и разборчиво разговаривать</li>
    <li>Ваши касания (или призрачное оружие) могут наносить вред живым (игнорирует броню)</li>
</ul>
<p>Ваше проявление продолжается до тех пор, пока вы концентрируетесь; боль, шок или прямой солнечный свет точно навредят
    вашей концентрации<span>.</span></p>

<h3><strong>Привязанный</strong></h3>
<p>Выберите что-то, к чему вы привязаны: ваши останки, место вашей смерти, важный для вас предмет или что-то подобное.&nbsp;</p>
<p><strong>Когда</strong> <strong>ваши ОЗ падают до 0,</strong> выберите Последствие и ваша эссенция рассеивается до
    следующего рассвета. Вы снова проявляетесь с половиной от максимальных ОЗ около того, к чему вы привязаны.&nbsp;
    Если то, к чему вы были привязаны, было уничтожено, получите Финальное последствие.</p>

<h1>Последствия</h1>

<h3><strong>Похититель тел</strong></h3>
<p><strong>Вселяясь в добровольца или кого-то без сознания,</strong> потеряйте 1к4 ОЗ и получите контроль над действиями
    жертвы. Используйте свои характеристики и ходы для любых действий которые жертва будет делать находясь под вашем
    контролем.&nbsp;</p>
<p><strong>Заставляя сделать жертву что-то, что противоречит её Инстинкту или что-то, что она находит отвратительным или
    ужасным</strong>, бросьте+ХАР:</p>
<p><strong>На 10+&nbsp;</strong>жертва всё равно делает что вы хотите</p>
<p><strong>На 7-9 </strong>она сопротивляется и не делает то что вы хотите и вы больше никогда не сможете заставить её
    сделать это, однако вы сохраняете контроль.<br><strong>На 6-</strong> жертва изгоняет вас из себя. Отметьте
    Последствие и вы больше никогда не сможете в неё вселиться.</p>
<p><strong>Когда ваша жертва получает урон</strong>, вы получаете столько же урона. Если ваша жертва умирает, но у вас
    ещё есть ОЗ, вы можете поддерживать её жизнь пока одержимость не закончится.</p>


<h3><strong>Срыв</strong></h3>
<p><strong>Вы впадаете в бездумную, бесчувственную ярость</strong> которая длится до следующего восхода. Спросите
    Ведущего какие мгновения вы можете вспомнить.</p>

<h3><strong>Нестабильный</strong></h3>
<p><em>Требуется:&nbsp;Срыв</em><br>Вы склонны к эпизодам бездумной, бесчувственной ярости (как в Срыве). <strong>Каждый
    раз когда у вас выпадает 6-</strong>, Ведущий может решить что вы впадаете в такую ярость.</p>
<h3><strong>Призрачное присутствие</strong></h3>
<p>Ваше присутствие (даже если вы невидимы) беспокоит зверей и детей. Воздух вокруг вас заметно холоднее, особенно когда
    вы проявляете себя. <strong>Используя запугивание и своё жуткое присутствие чтобы Договориться</strong>, бросайте с
    преимуществом.</p>

<h3><strong>Потусторонний</strong></h3>
<p><strong>Когда вы проявляете себя в материальном мире</strong>, странные вещи начинают происходить вокруг вас: статуи
    плачут, камни истекают кровью, растения вянут, вода капает на потолок и т. п.</p>
<h3><strong>Полтергейст</strong></h3>
<p><strong>Когда вы злитесь</strong>, потеряйте 1к4 ОЗ и получите столько же Ярости. Потратьте Ярость 1-к-1 чтобы:</p>
<ul>
    <li>Расколоть, сломать или уничтожить несколько обычных маленьких предметов или один большой.</li>
    <li>
        <p>Бросить в кого-то предмет. Бросьте+ЛОВ.</p>
        <ul>
            <li><strong>На 10+</strong> нанесите урон (он <em>мощный</em>)</li>
            <li><strong>На 7-9</strong> нанесите урон (он <em>мощный</em>) и потеряйте 1к4 ОЗ</li>
        </ul>
    </li>
    <li>
        <p>Атаковать кого-то телекинетической силой. Бросьте+ИНТ.</p>
        <ul>
            <li><strong>На 10+</strong> бросьте свою жертву в место которое вы можете видеть и удерживайте её там, теряя
                1 ОЗ каждый раз когда она пытается выбраться из вашей телекинетической хватки.
            </li>
            <li><strong>На 7-9</strong> также как на 10+, но потеряйте 1к4 ОЗ.</li>
        </ul>
    </li>
</ul>

<h3><strong>Добыча</strong></h3>
<p>Бледный Охотник учуял ваш запах. Ожидайте скорого визита.</p>

<h3><strong>Спектр</strong></h3>
<p><strong>Ужасая живых, не связанных с вашей Ужасной целью</strong>, восстановите 1к8 ОЗ или избавьтесь от одной Травмы
    по вашему выбору.</p>

<h2><strong>Финальное последствие</strong></h2>
<p>Ваша непрочная связь с человечеством разрывается и вы становитесь монстром под контролем Ведущего.</p>
   
`