import { Link } from 'gatsby'
import React from 'react'

import DwMoveLink from '@/components/DwMoveLink'
import DwTag from '@/components/DwTag'
import sortByName from '@/utils/sortByName'
import generateDwPcClassPathById from '@/utils/generateDwPcClassPathById'

export default [
  {
    id: 'dwarf.background.mountain_dweller',
    name: 'Обитатель гор',
    text: (
      <>
        <p>Выбери ещё одну область экспертизы из списка хода <DwMoveLink id='dwarf.start.tongs_n_hammer'>Молот и Клещи</DwMoveLink>.</p>
        <p>Кроме того, <strong><DwMoveLink id='common.recover'>Восстанавливаясь</DwMoveLink></strong>, если ты находишься под землёй или в горах, ты не тратишь <DwTag id='supplies'>Припасы</DwTag>.</p>
      </>
    ),
  },
  {
    id: 'dwarf.background.hill_trader',
    name: 'Холмовой торговец',
    text: (
      <>
        <p><strong><DwMoveLink id='common.parley'>Договариваясь</DwMoveLink></strong>, или иначе пытаясь повлиять на кого-то цивилизованного, бросай с преимуществом.</p>
        <p>Кроме того, когда ты <strong><DwMoveLink id='common.set_camp'>Разбиваешь лагерь</DwMoveLink> и поёшь песни холмового народа</strong>, все, кто их слышит (включая тебя), восстанавливают дополнительные 1к6 <DwTag id='hp'>ОЗ</DwTag>.</p>
      </>
    ),
  },
  {
    id: 'dwarf.background.underground_dweller',
    name: 'Подземный житель',
    text: (
      <>
        <p>Твой народ копал жаднее и глубже, чем кто-либо ещё. Даже в полной темноте ты видишь так же ясно, как днём.</p>
        <p><strong>Кроме того, пока ты стоишь недвижимо в темноте</strong>, тебя нельзя увидеть обычными средствами, до тех пор, пока ты не сделаешь что-то, что тебя раскроет.</p>
      </>
    ),
  },
  {
    id: 'dwarf.start.tongs_n_hammer',
    name: 'Молот и клещи',
    text: (
      <>
        <p>Ты — умелый ремесленник<strong>. Выбери область своего мастерства:</strong></p>
        <ul>
          <li>Работа с камнем, укладка камня и прокладывание тоннелей.</li>
          <li>Кузнечество и металлургия.</li>
          <li>Драгоценные камни, кристаллы, ювелирные украшения и благородные металлы.</li>
          <li>Пиво и крепкий алкоголь.</li>
          <li>Инженерия и изобретения.</li>
        </ul>
        <p><strong>Создавая что-то в области своего мастерства (и имея подходящие инструменты, время и материалы)</strong>, брось +ТЕЛ.</p>
        <ul>
          <li>На <strong>10+</strong> — ты создаёшь, что хотел. Любой, кто осмотрит твоё творение, убедится в его отменном качестве: предмет получает свойство <DwTag id='dwarf_made'>Сделан дварфами</DwTag>. Опиши дополнительную особенность или свойство, которые говорит о том, что это — работа мастера.</li>
          <li>На <strong>7–9</strong> — ты создаёшь, что хотел. Любой, кто осмотрит твоё творение, убедится в его отменном качестве: предмет получает свойство <DwTag id='dwarf_made'>Сделан дварфами</DwTag>.</li>
          <li>На <strong>6−</strong> — ты всё равно создаёшь предмет, но он получился некачественным (по дварфийским стандартам) — он НЕ получает свойство <DwTag id='dwarf_made'>Сделан дварфами</DwTag>.</li>
        </ul>
        <p><strong><DwMoveLink id='common.spout_lore'>Копаясь в памяти</DwMoveLink> о чём-то, связанным с твоей областью мастерства</strong>, ты получаешь преимущество на бросок и на 10+ можешь задать Ведущему ещё один вопрос и получить честный ответ.</p>
      </>
    ),
    quote: {
      text: `Если мой клан не может предоставить мне инструменты, я сделаю их сам!`,
      author: 'Неизвестный дварф-шахтёр',
    },
  },
  {
    id: 'dwarf.start.armored',
    name: 'Бронированный',
    text: (
      <p>Ты игнорируешь свойство брони <DwTag id='clumsy'>Неуклюжая</DwTag>, а <DwTag id='weight'>вес</DwTag> щитов для тебя уменьшается на 1.</p>
    ),
  },
  {
    id: 'dwarf.start.unbreakable',
    name: 'Несломимый',
    text: (
      <p><strong><DwMoveLink id='common.defy_danger'>Спасаясь от угрозы</DwMoveLink> стойкостью или выносливостью</strong>, ты не можешь провалиться. Считай броски 6− как 7–9.</p>
    ),
  },
  {
    id: 'dwarf.start.sniff_for_gold',
    name: 'Нюх на золото',
    text: (
      <>
        <p><strong><DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink></strong>, ты можешь спросить Ведущего <em>«Есть ли здесь сокровища и если есть, то где они?»</em> бесплатно, даже на 6−.</p>
        <p>Кроме того, <strong>оценивая нечто потенциально ценное</strong>, ты знаешь — подлинное оно или подделка, а также его примерную цену.</p>
      </>
    ),
    quote: {
      text: `Монеты людей как их слова — им нельзя доверять. Носи с собой мерные весы и держи топор наготове.`,
      author: 'Дварфийская поговорка',
    },
  },
  {
    id: 'dwarf.advanced.stone_speaker',
    name: 'Говорящий с камнем',
    text: (
      <p><strong><DwMoveLink id='common.discern_realities'>Изучая обстановку</DwMoveLink> под землёй</strong>, ты можешь задать ещё один вопрос, даже на 6−. Этот дополнительный вопрос может быть чем угодно, не только из списка. Ты получаешь ответ благодаря своей естественной связи с землёй и камнем.</p>
    ),
  },
  {
    id: 'dwarf.advanced.strong_back',
    name: 'Крепкая спина',
    text: (
      <p><strong>Твоя <Link to={generateDwPcClassPathById('dwarf', 'capacity')}>нагрузка</Link> равна 10. Ты можешь носить</strong> до 4 веса как лёгкую нагрузку, 5–7 веса как среднюю и 8–10 веса как тяжёлую.</p>
    ),
  },
  {
    id: 'dwarf.advanced.tools_of_war',
    name: 'Инструменты войны',
    text: (
      <>
        <p><strong><DwMoveLink id='common.hack_n_slash'>Рубя и кромсая</DwMoveLink> топором, молотом или киркой</strong>, на 10+ выбери дополнительно одно:</p>
        <ul>
          <li>Нанеси ещё +1к4 урона.</li>
          <li>Навсегда уменьшить броню врага на 1 (если она была).</li>
        </ul>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.ancestral_knowledge',
    name: 'Знания предков',
    text: (
      <>
        <p><DwMoveLink id='common.spout_lore'>Копаясь в памяти</DwMoveLink>, ты можешь бросать ТЕЛ вместо ИНТ.</p>
        <p>Кроме того, <strong>выбери тему</strong>, на которую тебе известны легенды и сказания предков:</p>
        <ul>
          <li>Горы и крепости.</li>
          <li>Глубинные дороги и Подземье.</li>
          <li>Ремесло и мастерство.</li>
          <li>Категория существ из хода <DwMoveLink id='dwarf.advanced.ancient_enemies'>Древние враги</DwMoveLink>.</li>
        </ul>
        <p><strong><DwMoveLink id='common.spout_lore'>Копаясь в памяти</DwMoveLink> по выбранной вами теме</strong>, бросай с преимуществом.</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.greed_is_good',
    name: 'Жадность — это хорошо',
    text: (
      <>
        <p><strong>Когда ты объявляешь сокровище или особо ценный предмет целью своей жадности</strong>, получи 3 <em>Жадности</em>.</p>
        <p><strong>Совершая ход, чтобы заполучить цель твоей жадности</strong>, ты можешь потратить 1 <em>Жадности</em>, чтобы получить преимущество на бросок.</p>
        <p>Ты не можешь выбрать новую цель для своей жадности, пока не заполучишь уже объявленную, или же она не станет абсолютно недостижимой (переварена Тарраском и т. п.). Ты не можешь просто сдаться, потому что её слишком тяжело заполучить.</p>
      </>
    ),
    quote: {
      text: `Не то что я имею что-то против бессмысленного насилия, но зачем ты душишь этого старика?`,
      author: 'Готрек Гурниссон, Троллодав',
    },
  },
  {
    id: 'dwarf.advanced.stonelike',
    name: 'Словно камень',
    text: (
      <>
        <p>Благодаря своей толстой коже, <strong>получая урон</strong>, ты игнорируешь свойство вражеской атаки <em>Месиво</em>.</p>
        <p><strong>Пока ты стоишь на земле или камне</strong>, тебя нельзя сбить с ног ничем, слабее удара тролля, и ты игнорируешь свойство вражеской атаки <em>Мощная</em> (тебя всё ещё можно сдвинуть с места).</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.nobody_wants_a_beer',
    name: 'Пивка никто не желает?',
    text: (
      <>
        <p><strong>Ты всегда можешь потратить <DwTag id='supplies'>Припасы</DwTag>, чтобы достать крепкую выпивку</strong>. Больше одного такого крепкого напитка достаточно, чтобы не-дварф напился в&nbsp;стельку.</p>
        <p><strong>Когда ты <DwMoveLink id='common.recover'>Восстанавливаешься</DwMoveLink></strong> и делишься крепкой выпивкой (потрать <DwTag id='supplies'>Припасы</DwTag>), любой, кто её выпьет, восстанавливает дополнительное ОЗ, равное его ТЕЛ (не менее 1).</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.wanna_drink',
    name: 'Выпить хочешь?',
    text: (
      <>
        <p><strong><DwMoveLink id='common.parley'>Договариваясь</DwMoveLink> с кем-то, с кем ты выпил</strong>, бросай ТЕЛ вместо ХАР.</p>
        <p>Кроме того, <strong>устраивая <DwMoveLink id='common.carouse'>Пирушку</DwMoveLink> или участвуя в ней</strong>, добавь ТЕЛ к своему броску.</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.shieldbreaker',
    name: 'Щитолом',
    text: (
      <p><strong>Получая урон, нося щит</strong>, ты можешь решить что щит принимает на себя всю атаку — ты получаешь лишь половину урона, но щит уничтожается или ты навсегда теряешь его.</p>
    ),
  },
  {
    id: 'dwarf.advanced.shredder',
    name: 'Кромсатель',
    text: (
      <>
        <p><strong>Сражаясь топором</strong>, бросай урон с преимуществом.</p>
        <p>Для тебя все топоры имеют свойство <em>Метательный</em>.</p>
        <p><strong>Прорубая топором что-то деревянное (или из подобного материала)</strong>, считай 6− как 7–9. </p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.crusher',
    name: 'Крушитель',
    text: (
      <>
        <p><strong>Сражаясь молотом, булавой или киркой</strong>, бросай урон с преимуществом.</p>
        <p>Для тебя вес <strong>молотов, булав и кирок</strong> уменьшается на 1, вплоть до 0.</p>
        <p><strong>Используя молот или булаву чтобы разломать что-то каменное (или из подобного материала)</strong>, считай 6− как 7–9.</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.iron_soul',
    name: 'Железная душа',
    text: (
      <>
        <p><strong>Когда на тебя воздействует вредоносная магия</strong>, урон бросается с помехой, а негативные эффекты ослаблены или уменьшены.</p>
        <p><strong>Когда ты получаешь магическое исцеление или зачарование</strong>, исцеление получает помеху или ополовинивается, а зачарования работают лишь наполовину или ослабляются.</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.tremor_sense',
    name: 'Чувство вибрации',
    text: (
      <>
        <p><strong>Пока ты касаешься земли и враг должен застать тебя врасплох</strong>, этого не происходит и ты действуешь первым.</p>
        <p>Кроме того, <strong>касаясь земли</strong>, ты можешь точно определять местонахождение касающихся земли существ в пределах <em>Удара копья</em>, даже если не видишь их. </p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.ancient_enemies',
    name: 'Древние враги',
    text: (
      <>
        <p>Твоя ненависть не имеет конца и края. Выбери своих древних врагов, опиши свою ненависть к ним и то, какие она оставила на тебе следы:</p>
        <ul>
          <li>Великаны и их родственники.</li>
          <li>Дикие орды.</li>
          <li>Драконы и их слуги.</li>
          <li>Исчадия, дьяволы и демоны.</li>
          <li>Клятвопреступники и братоубийцы.</li>
          <li>Маги и их слуги.</li>
          <li>Твари, что Обитают Внизу.</li>
          <li>Эльфы и их союзники.</li>
          <li>_______________________</li>
        </ul>
        <p><strong><DwMoveLink id='common.defend'>Вставая на защиту</DwMoveLink> от своего древнего врага</strong>, ты можешь бесплатно отвлечь на себя всё внимание от того, что ты защищаешь, не тратя <em>Готовность</em>.</p>
        <p><strong><DwMoveLink id='common.session_end'>В конце сессии</DwMoveLink></strong>, если ты преодолел, перехитрил или иначе победил своего древнего врага, отметь опыт.</p>
      </>
    ),
    quote: {
      text: `Почитай предков, повинуйся своему королю, гордо носи оружие, не бойся врагов, ненавидь орков, не доверяй эльфам, и ты точно не ошибёшься.`,
      author: 'Старая дварфийская поговорка',
    },
  },
  {
    id: 'dwarf.advanced.stone_durability',
    name: 'Каменная выносливость',
    text: (
      <>
        <p><strong>Когда ты должен получить <em>Травму</em></strong> (например, <em>Ослаблен</em>), ты можешь решить не получать её. Ты не можешь сделать это ещё раз, пока не <DwMoveLink id='common.set_camp'>Разобьёшь лагерь</DwMoveLink> на сырой земле, камне, или кровати из этих материалов.</p>
        <p>Кроме того, <DwMoveLink id='common.set_camp'>Разбивая лагерь</DwMoveLink>, ты исцеляетесь от всех обычных болезней и ядов (но не от серьёзных недугов). Тебе не нужна ни кровать, ни магия.</p>
      </>
    ),
  },
  {
    id: 'dwarf.advanced.ancient_allies',
    name: 'Древние союзники',
    text: (
      <>
        <p><strong>Призывая к себе на помощь связанного с тобой клятвой древнего союзника</strong>, скажи Ведущему — кто (или что) он такое. Он прибудет в течение нескольких дней. По его прибытии выбери одно, а Ведущий выберет ещё одно:</p>
        <ul>
          <li>Он скажет что его клятва выполнена и его нельзя будет позвать на помощь снова.</li>
          <li>Ты и твой народ будете должны ему равнозначную услугу.</li>
          <li>Он захочет получить долю от любой будущей добычи.</li>
          <li>Он потребует заплатить тёмную и ужасную цену (Ведущий скажет, какую).</li>
        </ul>
      </>
    ),
    quote: {
      text: (
        <p>— Никогда не думал что умру сражаясь бок с боком с эльфом.<br/>
          — А как насчёт бок о бок с другом?<br/>
          — Эй, это я могу.</p>
      ),
      author: '',
    },
  },
  {
    id: 'dwarf.advanced.training',
    name: 'Выучка',
    text: (
      <p>Получи сложный ход <Link to={generateDwPcClassPathById('warrior')}>Воина</Link> или <Link to={generateDwPcClassPathById('savage')}>Дикаря</Link>.</p>
    ),
  },
  {
    id: 'dwarf.capstone.great_master',
    name: 'Великий мастер',
    text: (
      <p>Выбери ещё одну специализацию для хода <DwMoveLink id='dwarf.start.tongs_n_hammer'>Молот и клещи</DwMoveLink>.</p>
    ),
  },
  {
    id: 'dwarf.capstone.master_craftsmith',
    name: 'Мастер-ремесленник',
    text: (
      <p>Бросая на ход <DwMoveLink id='dwarf.start.tongs_n_hammer'>Молот и клещи</DwMoveLink>, на 12+ ты зачаровываешь созданную вещь магией. Опиши желаемый эффект и Ведущий назовёт тебе дополнительную цену или последствие. Оплатив цену или приняв последствие, ты получаешь желаемый эффект.</p>
    ),
  },
  {
    id: 'dwarf.capstone.eternal_enemies',
    name: 'Вечные враги',
    text: (
      <>
        <p><em>Требуется:</em> <DwMoveLink id='dwarf.advanced.ancient_enemies'>Древние враги</DwMoveLink></p>
        <p>Выбери себе ещё одних древних врагов для хода <DwMoveLink id='dwarf.advanced.ancient_enemies'>Древние враги</DwMoveLink>.</p>
        <p><strong><DwMoveLink id='common.hack_n_slash'>Рубя и кромсая</DwMoveLink> своих древних врагов</strong>, считай 6− как 7–9, а 7–9 как 10+.</p>
      </>
    ),
  },
  {
    id: 'dwarf.capstone.mithril_soul',
    name: 'Мифриловая душа',
    text: (
      <>
        <p><em>Требуется:</em> <DwMoveLink id='dwarf.advanced.iron_soul'>Железная душа</DwMoveLink></p>
        <p><strong>Когда на тебя воздействует вредоносная магия</strong>, урон ополовинивается, а негативные эффекты значительно ослабляются или вовсе на тебя не действуют.</p>
        <p><strong>Когда ты получаешь магическое исцеление или зачарование</strong>, исцеление ОЗ больше не уменьшается, а зачарования работают как и должны.</p>
      </>
    ),
  },
]
  .map(
    move => ({
      ...move,
      classId: 'dwarf',
    }),
  )
  .sort(sortByName)
