const React = require('react')

module.exports = {
  id: 'noble',
  name: 'Дворянин',
  description: [
    `Звон бокалов, яркие одежды, элегантные танцы, изысканные блюда и дуэли за честь девушки — это жизнь дворян в представлении простолюдинов. Отчасти это правда, но лишь отчасти. Это — всего лишь фасад, а за кулисами идёт настоящая борьба, в которой ты можешь доверять только своей родне, а все остальные, как бы они не улыбались, всегда ищут возможности разбогатеть за твой счёт.`,
    `Войны начинают не военные. Войны начинают дворяне. И битвы за круглыми столами отнюдь не менее впечатляющи, чем резня на поле боя. Вместо копий — острые слова, а вместо мечей — крепкие аргументы. Мало кто знает, какова на самом деле цена мира, когда каждый знатный род стремится разорвать друг другу глотки за клочок земли или кроху влияния.`,
    `К счастью, ты не погряз в политических скандалах и избежал кабального брака во благо страны. И теперь ты можешь помочь своей семье, как когда-то смогли твои великие предки, когда заложили основы твоего нынешнего благосостояния — выйдя в этот опасный, безумный, дикий мир.`,
  ],
  nameList: ['Аделина', 'Адольф', 'Белинда', 'Брумхильда', 'Генрих', 'Клартон', 'Луиза', 'Отфрид', 'Рихмут', 'Роберт', 'Родерик', 'Франка', 'Шарлотта'],
  featureList: [
    {
      name: 'Взгляд',
      variantList: ['проницательный', 'надменный', 'жадный'],
    },
    {
      name: 'Причёска',
      variantList: ['тиара', 'причудливая шляпа', 'струящиеся локоны'],
    },
    {
      name: 'Одежда',
      variantList: ['показная', 'стильная', 'военная'],
    },
    {
      name: 'Тело',
      variantList: ['пухлое', 'безукоризненно ухоженное', 'изящное'],
    },
  ],
  hpBonus: 16,
  damageDice: 6,
  striveList: [
    {
      name: 'Знаменитость',
      text: 'Увеличь собственную и семейную известность.',
    },
    {
      name: 'Положение обязывает',
      text: 'Помоги черни, когда другие властьимущие не могут или не хотят.',
    },
    {
      name: 'Гордость',
      text: 'Покажите превосходство твоей семьи над другой знатью.',
    },
  ],
  optionalQuestionList: [
    'Где находится твой дом и как он выглядит?',
    'Как ты относишься к простому народу, и как он относится к тебе?',
    'Какие манеры отличают тебя от простолюдина?',
    'Чем твоя семья наиболее известна?',
  ],
  backgroundIdList: [
    'noble.background.decadent',
    'noble.background.distant_relative',
    'noble.background.last_scion',
  ],
  startMoveIdList: [
    'noble.start.privilege',
    'noble.start.education',
    'noble.start.landlord',
  ],
  advancedMoveIdList: [
    'noble.advanced.courtier',
    'noble.advanced.hero_blood',
    'noble.advanced.high_society_style',
    'noble.advanced.matter_of_coins',
    'noble.advanced.natural_leader',
    'noble.advanced.round_table',
    'noble.advanced.silver_tongue',
    'noble.advanced.skilled_duelist',
    'noble.advanced.throw_gauntlet',
  ],
  capstoneMoveIdList: [
    'noble.capstone.family_pride',
    'noble.capstone.focus_fire',
  ],
}