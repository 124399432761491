const React = require('react')

module.exports = {
  id: 'priest',
  name: 'Жрец',
  description: [
    ``,
  ],
  nameList: [''],
  featureList: [
    {
      name: 'Взгляд',
      variantList: ['', ''],
    },
    {
      name: 'Причёска',
      variantList: ['', ''],
    },
    {
      name: 'Одежда',
      variantList: ['', ''],
    },
    {
      name: 'Тело',
      variantList: ['', '', ''],
    },
  ],
  hpBonus: 0,
  damageDice: 0,
  striveList: [
    {
      name: '',
      text: '',
    },
  ],
  backgroundIdList: [
    '',
  ],
  startMoveIdList: [
    '',
  ],
  advancedMoveIdList: [
    '',
  ],
  capstoneMoveIdList: [
    '',
  ],
  specialMoves: {
    title: '',
    list: [
      '',
    ]
  },
}

const foo = `



<div><img src="systems/dungeonworld/assets/icons/commodities/treasure/token-gold-cross.webp"></div>
<p><em>Этот мир погряз в хаосе. Меж оплотов веры и цивилизации&nbsp;— обширные пустоши, полные диких зверей, живых
    мертвецов
    и других чудищ. В этих землях нет богов. Вот почему там так нужны ты.</em></p>
<p><em>Нести свет своего божества во тьму дикости и варварства&nbsp;— это не просто твоя природа, это твоё призвание.
    Именно
    на тебя пал долг мечом, булавой и магией обращать людей в свою веру, спускаться в самые страшные глубины диких
    земель, чтобы посадить там семена веры. Некоторые говорят, что богов нужно хранить в своем сердце. ты знаете, что
    это чушь. Всё божественное живёт на острие клинка.</em></p>
<p><em>Покажите этому миру, кто его истинный владыка. </em></p>
<h2>Имена</h2>
<p>Уэсли, Бринтон, Джон, Сара, Хоторн, Элис, Кларк, Ленора, Пётр, Далия, Кармин;</p>
<p> Дарга, Эльфар, Герда, Рургош, Бьёрн, Драммонд, Хельга, Сиггруг, Фрея</p>
<h2>Внешность</h2>
<p>Выбери один вариант в каждой строчке:</p>
<ul>
    <li>Добрые глаза, проницательные глаза или грустные глаза;</li>
    <li>Тонзура, странная причёска или выбритая голова;</li>
    <li>Длинная сутана, церемониальные одежды или обычная одежда;</li>
    <li>Худой, угловатая фигура или дряблое тело.</li>
</ul>
<h2>Характеристики</h2>
<p>Твои максимальные хиты: <strong>16+ТЕЛ</strong></p>
<p>Твой базовый урон равен <em>d6</em>.</p>
<p>Распределите эти модификаторы по характеристикам <strong>+2, +1, +1, +0, +0, -1</strong></p>

<h2>Личность персонажа</h2>



<h3><strong>Стремление</strong></h3>
<p>Выбери до трёх Стремлений своего персонажа. Выбирайте из вариантов ниже, придумайте своё собственное или
    воспользуйтесь <em>списком Стремлений</em>.
</p>
<p>Стремления олицетворяют то как персонаж взаимодействует с миром и следование или иначе использование их в игре
    награждается опытом в <DwMoveLink id='common.session_end'>Конце сессии</DwMoveLink>.</p>

<blockquote>
    <h3><strong>Приверженность</strong></h3>
    <p>Переносите трудности, чтобы следовать предписаниям своей веры.</p>
    <h3><strong>Жертва</strong></h3>
    <p>Подвергните себя опасности чтобы кому-то помочь.</p>
    <h3><strong>Подтверждение</strong></h3>
    <p>Докажите превосходство своего божества.</p>
    <h3><strong>Сострадание</strong></h3>
    <p>Принесите облегчение страдающему персонажу Ведущего.</p>
    <h3><strong>Ортодокс</strong></h3>
    <p>Строго следуйте догмам и правилам, даже если это вызовет проблемы.</p>
</blockquote>


<h3><strong>Теперь выбери предысторию и получите соответствующий ход:</strong></h3>

<blockquote>
    <h3><strong><em>Предзнаменованное рождение</em></strong></h3>
    <p>Ты были рождены во время затмения, во время катаклизма или другого великого события. Может ты несёте какую-то
        отметину в форме символа твоего бога?</p>
    <p><strong>Когда ты должны потерять Милость</strong>, ты можешь решить не терять её. ты не можете сделать этого
        снова пока не <em>Разобьёте лагерь</em>.</p>
</blockquote>

<blockquote>
    <h3><strong><em>Боевой орден</em></strong></h3>
    <p>Муштра с самого принятия в орден, тренировки и наставники закалили тебя и научили нести слово своего бога в
        оружием в руках. Как тренировки или битвы отметили тебя? Может шрамами и небольшими увечьями, вроде потерянного
        пальца?</p>
    <p><strong>Побеждая значительного врага своего Божества</strong>, получите Милость.</p>
    <p><strong>Сражаясь во имя своего божества</strong>, увеличьте свой урон до к8.</p>
    <p><strong><em>Рубя и Кромсая</em></strong>, ты можешь потратить Милость чтобы получить преимущество на бросок.</p>
</blockquote>

<blockquote>
    <h3><strong><em>Целитель</em></strong></h3>
    <p>Помочь тем, кто нуждается и окончить страдания тех, кого уже не спасти&nbsp;— это про тебя.</p>
    <p><strong>Избавляя или ослабляя мучения персонажа Ведущего</strong>, получите Милость.</p>
    <p><strong>Сразу получите ход <em>Божественная защита</em>.</strong></p>
    <p><strong>Когда ты <em>Восстанавливаетесь</em> или <em>Разбиваете Лагерь</em></strong>, ты можешь тратить Милость
        вместо <em>Припасов</em>.</p>
</blockquote>

<blockquote>
    <h3><strong><em>Фанатик</em></strong></h3>
    <p>Нет ничего важнее твоей миссии и твоей веры. От тебя зависит всё. ты не имеет права провалиться.</p>
    <p><strong><em>Приобщаясь</em> со своим божеством</strong>, всегда считайте 6− как 7–9.</p>
    <p><strong>Если у тебя нет Милости своего божества</strong>, ты можешь отметить Травму или потерять 2к4 ОЗ чтобы
        получить 1 Милость.</p>
</blockquote>

<h1>Основа класса персонажа</h1>
<h2>Ты начинаете игру со следующими ходами:</h2>

<h3><strong><em>Послушник</em></strong></h3>
<p>Вы&nbsp;— сосуд для божественной воли. <strong>Милость</strong> олицетворяет собой твоё текущее положение перед твоим
    божеством. Максимальная Милость равна твоей Мудрости или Харизме, смотря что выше.</p>
<p><strong>Когда у тебя есть Милость</strong>, Домены твоего божества реагируют на твоё присутствие (показывается солнце,
    цветы распускаются, знать кланяется, демон шипит и т. п.) или как-то внешне проявляются на тебя (пробегающая дымка
    вокруг рук, светящиеся глаза, мерцающие символы на коже и т. п.).</p>

<h3><strong><em>Воззвать</em></strong></h3>
<p><strong>Когда ты просите у своего божества помощь в час нужды</strong>, выбери эффект:</p>
<ul>
    <li>
        <p>Выпустить Домен твоего божества чтобы навредить или помешать врагу неподалёку.</p>
    </li>
    <li>
        <p>Контролировать Домен твоего божества себе в помощь.</p>
    </li>
    <li>
        <p>Командовать Почитателями твоего божества.</p>
    </li>
</ul>
<p>…и бросьте +Милость.</p>
<p><strong>На 10+</strong> эффект происходит.</p>
<p><strong>На 7–9</strong> эффект происходит, но выбери одно:</p>
<ul>
    <li>
        <p>Эффект ослаблен или мимолётен</p>
    </li>
    <li>
        <p>Ты привлекаете к себе нежелательное внимание или оказываетесь в сложном положении</p>
    </li>
    <li>
        <p>Твойе тело страдает от проводимой мощи&nbsp;— отметьте Травму</p>
    </li>
    <li>
        <p>Ты отдаляетесь от сферы влияния своего божества, потеряйте 1 Милости</p>
    </li>
</ul>

<h3><strong><em>Приобщение</em></strong></h3>
<p><strong>Проводя хотя бы несколько минут почитая твоё божество подходящим для твоей веры способом (молитвой,
    медитацией и т. п.),</strong> бросьте +МДР или +ХАР. Делая это в месте священном для твоей веры, бросайте с
    преимуществом.</p>
<p><strong>На 10+</strong> выбери 2.</p>
<p><strong>На 7–9</strong> выбери 1:</p>
<ul>
    <li>
        <p>Получите 1 Милости (можно выбирать дважды)</p>
    </li>
    <li>
        <p>Ты получаешь какое-то знание в рамках сферы влияния твоего божества.</p>
    </li>
    <li>
        <p>Ты получаешь временный дар в рамках сферы влияния твоего божества.</p>
    </li>
</ul>

<h3><strong><em>Божество: ____________</em></strong></h3>
<p>Ты служите и поклоняетесь божеству, которое дарует тебе силы. <strong>Выберите</strong> себе уже существующее божество
    <strong>или создайте</strong> своё (обсудите это с Ведущим): <strong>дайте ему/ей имя</strong> и <strong>выбери
        сферы</strong> влияния божества заполняя пустые места:</p>
<p><strong>Смертные знают его как…</strong> ___________________ (Бледная Королева, Сакеллус Отец Моря, Зорика, Великая
    Тень и т. п).</p>
<p><strong>Его первый Домен … </strong>(солнце, моря, небеса) ________________</p>
<p><strong>Его второй Домен это…</strong> (любовь, смерть, война, ветер) ________________</p>
<p><strong>Его Почитают…</strong> (дворяне, дварфы, маги) ________________</p>
<p><strong>Его Врагами являются…</strong> (демоны, нежить, орки, предатели) ________________</p>
<p>Для вдохновения ты можешь посмотреть эту <em>страницу</em>.</p>

<p><em>СВЯТОЙ СИГМАР, БЛАГОСЛОВИ ЭТО ИЗУРОДОВАННОЕ ТЕЛО</em></p>
<p><em>— Виктор Зальцпайр, Охотник на ведьм</em></p>

<h3><strong><em>Божественная церемония</em></strong></h3>
<p><strong>Подготавливая церемонию чтобы попросить о чём-то своё божество</strong>, скажите Ведущему, какого
    результата ты хотите получить. Этот результат достижим всегда, но Ведущий может поставить перед тобой от одного до
    четырех условий из списка:</p>
<ul>
    <li>Церемония займёт несколько дней/недель/месяцев</li>
    <li>Чтобы начать церемонию, тебе понадобится ______________</li>
    <li>Ты должны проводить церемонию в Священном месте (Например ______)</li>
    <li>Чтобы закончить церемонию тебе понадобится нужна помощь ___________</li>
    <li>Враги богов попытаются вмешаться и результат будет либо ограничен, либо каким-то образом искажён/проклят.</li>
    <li>Церемония подвергнет риску тебя и твоих союзников из-за _____________</li>
    <li>Вам нужно пожертвовать или потратить ____________, чтобы сделать это.</li>
</ul>

<h2>Снаряжение</h2>
<p>Твойа <em>нагрузка</em> равна <strong>9. </strong>Каждый предмет, включая <em>Неопределённые</em>, исключая маленькие,
    добавляет свой вес к общей нагрузке.
</p>
<ul>
    <li>
        <p><strong>1-3 </strong>веса это лёгкая нагрузка (ты <em>быстры</em> и <em>тихи</em>)</p>
    </li>
    <li>
        <p><strong>4-6 </strong>нормальная нагрузка</p>
    </li>
    <li>
        <p><strong>7–9 </strong>тяжёлая (ты <em>медленны</em>, <em>шумны, тебе жарко</em> и <em>быстро устаёте</em>)
        </p>
    </li>
</ul>
<p><strong>Ты начинаете игру с:</strong></p>
<ul>
    <li><em>Припасами</em> (3 использования, вес 1)</li>
    <li><em>Символом божества</em>&nbsp;— опиши его! (<em>Мелкий</em>).</li>
</ul>
<p><strong>Выбери два:</strong></p>
<ul>
    <li><em>Посох</em> (взмах меча, двуручное, вес 1)</li>
    <li><em>Булава</em> (взмах меча, вес 1)</li>
    <li><em>Клевец</em> (взмах меча, вес 1)</li>
    <li><em>Освящённая лёгкая броня</em> (броня 1, вес 1)</li>
    <li><em>Припасы</em> (3 использования, вес 1)</li>
    <li><em>Зелье исцеления</em> (<em>Мелкий</em>).</li>
</ul>


<h1><strong>Сложные ходы</strong></h1>
<h3>Сразу выбери один из ходов ниже:</h3>

<h3><strong><em>Божественная защита</em></strong></h3>
<p><strong>Касаясь кого-то и моля своё божество о защите</strong>, бросьте +Милость.</p>
<p><strong>На 10+</strong> выбери два варианта и примените их на себя или союзника.</p>
<p><strong>На 7–9</strong> выбери одно и ещё одно из списка 7–9 хода <em>Воззвать</em>.</p>
<ul>
    <li>
        <p>Цель восстанавливает к8 ОЗ</p>
    </li>
    <li>
        <p>Следующий бросок урона против цели получает помеху.</p>
    </li>
    <li>
        <p>Цель получает 1 Благословения (макс. 1) которое может потратить чтобы получить преимущество на бросок.</p>
    </li>
    <li>
        <p>Цель исцеляется от слабого негативного состояния (пьяный и т. п.) или обычной болезни в лёгкой стадии
            (простуда, чесотка и т. п).</p>
    </li>
</ul>

<h3><strong><em>Заклеймить ересь</em></strong></h3>
<p><strong>Когда кто-то совершает значительное святотатство против твоей веры и ты объявляете о их преступлениях им и
    другим</strong>, заклеймённые тобой и те, кто исполнят подобное деяние, теперь считаются Врагами твоего Божества. Это
    будет длиться до тех пор, пока они не искупят грехи таки образом, который подходит для природы и тяжести их
    святотатства.</p>

<h3><strong><em>Пилигрим</em></strong></h3>
<p>Твойа вера ведёт тебя. ты это она, а она это ты. <strong>
    <DwMoveLink id='common.defy_danger'>Спасаясь от угрозы</DwMoveLink>
    из-за чего-то, связанного с одним
    из Доменов твоего Божества</strong>, бросайте с преимуществом.
</p>

<h3><strong><em>Отповедь</em></strong></h3>
<p><strong>Демонстрируя свой священный символ чтобы отогнать сверхъестественных Врагов своего божества</strong>, бросьте
    +Милость.</p>
<p><strong>На 7+</strong> покуда ты демонстрируете свой символ, они будут держаться на расстоянии.</p>
<p><strong>На 10+</strong> слабые и жалкие существа бегут прочь в ужасе, а сильные ошеломляются на несколько мгновений.
</p>

<h3><strong><em>Пепел к пеплу</em></strong></h3>
<p><em>Требуется: <em>Отповедь</em></em></p>
<p><strong>Когда ты <em>Отповедуете</em></strong>, на 7+ все задетые существа также получают 2к4 игнорирующего броню
    урона. Бросайте урон отдельно для каждого задетого существа.</p>
<p>Существа убитые таким образом либо изгоняются из этого мира, либо возвращаются к тому, что их удерживает их
    здесь.</p>

<h3><strong><em>Безмятежность</em></strong></h3>
<p><strong>Пока у тебя нет Милости твоего божества</strong>, любой наносимый тебе урон получает помеху.</p>

<h3><strong><em>Щит веры</em></strong></h3>
<p><strong>Покуда у тебя есть Милость и ты не носишь броню</strong>, получите +1 брони.</p>
<p>К тому же, если <strong>ты также не носите никакого оружия кроме своей веры</strong>, получите ещё +1 брони.</p>

<h3><strong><em>Реликвия</em></strong></h3>
<p><strong>Когда ты должны потратить Милость</strong>, вместо этого ты можешь решить принести в жертву свой священный
    символ, уничтожая его в процессе. Вам придётся потратить время и ресурсы чтобы получить новый, Ведущий скажет каким
    образом.</p>

<h3><strong><em>Божественное вмешательство</em></strong></h3>
<p><strong>Когда ты или твой союзник неподалёку получаешь урон</strong>, ты можешь потратить 1 Милости чтобы ополовинить
    урон (округляя вниз) с помощью вмешательства твоего божества.</p>

<h3><strong><em>Защита от смерти</em></strong></h3>
<p><strong>Делая <em>Последний вздох</em>,</strong> бросайте +Милость вместо +ничего.</p>
<p><strong>Когда твой союзник</strong> <strong>неподалёку делает <em>Последний вздох</em> и</strong> <strong>ты</strong>
    <strong>просите божество вмешаться</strong>, он получает преимущество на бросок.</p>
<p>Союзник также получает преимущество на Последний вздох и ему необязательно находиться неподалёку от тебя если ты
    как-то отметили его или дали ему оберег, связанный с твоим божеством (амулет с символом божества, раскраску на лице,
    молитвенник и т. п.). Единовременно ты можешь иметь столько таких отметин или оберегов, сколько у тебя МДР.</p>

<h3><strong><em>Духовная пища</em></strong></h3>
<p><strong>Разговаривая с кем-то по душам</strong>, ты можешь <em>Договариваться</em> с ним с преимуществом. Если это
    Последователь, ты можешь решить вместо этого <em>Укрепить узы</em> (как если бы ты оплатили его Цену).</p>

<h3><strong><em>Вести паству</em></strong></h3>
<p><strong>Проповедуя толпе или группе</strong>, покуда ты продолжаете проповедовать, всё внимание будет приковано к
    тебе, и, если тебе придётся <em>Договариваться</em> с толпой, ты можешь бросать +МДР вместо +ХАР.</p>
<p><strong>Кроме того, когда ты <em>Договариваетесь</em> с толпой на тему твоей веры</strong>, на 7+, кроме обычного
    результата, выбери одно:</p>
<ul>
    <li>
        <p>Твойе имя и твоё послание распространяется</p>
    </li>
    <li>
        <p>Кто-то подходит к тебе, желая узнать больше.</p>

    </li>
</ul>
<h3><strong><em>Обращение</em></strong></h3>
<p><strong>Когда кто-то желает присоединиться к твоей вере и ты приветствуете его обрядом инициации и
    проповедью</strong>, бросьте +МДР<strong>.</strong></p>
<p><strong>На 10+ </strong>твоё Божество приветствует его и он становится Почитателем.</p>
<p><strong>На 7+</strong> он достоин, но он совершил небольшой грех который нужно искупить прежде чем твоё Божество
    примет его.</p>
<p><strong>Принимая неверующего в свою веру</strong>, если ты не делали этого недавно, получите 1 опыта или выбери 1
    вариант из списка хода <em>Приобщиться</em>.</p>


<h2>Получая уровни 6−10, ты можешь выбирать и эти ходы тоже:</h2>

<h3><strong><em>Апофеоз</em></strong></h3>
<p><strong>Проводя время в церемониальной молитве</strong> после того как ты взяли этот ход, выбери особенность
    ассоциируемую с твоим божеством (острые когти, крылья из сапфировых перьев, всевидящий третий глаз и т. п. из того
    что имеет смысл. Пообщайтесь с Ведущим на эту тему).</p>
<p><strong>Окончив эту молитву</strong>, ты навсегда обретаете эту особенность.</p>

<h3><strong><em>Очищение</em></strong></h3>
<p><em>Требуется: <em>Божественная защита</em></em></p>
<p><strong>Добавьте</strong> эти варианты в список <em>Божественной защиты</em>:</p>
<ul>
    <li>
        <p>Цель исцеляется от обычного яда. Действие мощных или сверхъестественных ядов как минимум замедляется.</p>
    </li>
    <li>Ты можешь частично исцелять сильные болезни или болезни в поздних стадиях, но они потребуют традиционных методов
        лечения для полного избавления. Течение сверхъестественных болезней как минимум замедляется.
    </li>
    <li>
        <p>Одна из Проблемных ран стабилизируется, хотя бы на время</p>
    </li>
    <li>
        <p>Ты изгоняете зловредное влияние на разум или душу цели. Особо мощное влияние лишь временно подавляется и
            может потребовать проведения <em>Божественной церемонии</em> для полного избавления.</p>
    </li>
</ul>

<h3><strong><em>Прах к праху</em></strong></h3>
<p><em>Требуется: <em>Пепел к пеплу</em></em></p>
<p>Урон увеличивается до 2к8 и существа убитые этим уроном либо полностью уничтожаются, либо изгоняются из этого мира и
    любая связь удерживающая их в этом мире также уничтожается (на выбор Ведущего).</p>

<h3><strong><em>Анафема</em></strong></h3>
<p><strong><em>Рубя и кромсая</em> Врагов твоего божества</strong>, ты можешь бросать +Милость вместо +Силы и твой урон
    против них становится к10, <em>игнорируя броню</em>.</p>

<h3><strong><em>Чудотворец</em></strong></h3>
<p><em>Требуется: <em>Божественная защита</em></em></p>
<p><strong><em>Взывая</em> или моля о <em>Божественной защите</em></strong>, на 12+ мощь твоего божества проходит через
    тебя, усиливая эффект чудесным образом, Ведущий скажет каким.</p>

<h3><strong><em>Молитвенный круг</em></strong></h3>
<p><em>Требуется: <em>Божественная защита</em></em></p>
<p><strong>Моля о <em>Божественной защите</em></strong>, ты можешь решить применить её эффекты на любых союзников в
    радиусе <em>Близко</em>, вместе с твоей основной целью.</p>

<h3><strong><em>Жнец</em></strong></h3>
<p><strong>Проводя время после конфликта посвящая победу твоему божеству и разбираясь с павшими</strong>, получите либо
    преимущество на следующий ход либо один вариант из списка <em>Приобщения</em>.</p>


<header>
    <h1>Божество</h1>
</header>
<section>
    <p><strong>Домены</strong> это сферы влияния божества&nbsp;— то, что ему важно и над чем оно имеет власть. Это
        абстрактные концепты, вроде «Войны» или какая-то часть мира, например «Земля». К примеру, «Жизнь» и «Разложение»
        уже даёт представление о том что это за божество. Жрец можете повелевать Доменами и призывать себе их на помощь
        когда потребуется.</p>
    <p><strong>Почитатели </strong>это те, кто, в целом уважает это божество и его жрецов. Это может быть социальная
        группа, к пример «нищие и обделённые», раса «гоблиноиды» или даже вид занятий «ремесленники». Жрецы могут
        повелевать ими пользуясь статусом проводника силы своего бога, к примеру требуя помощь или приказывая что-то
        сделать.</p>
    <p><strong>Враги</strong> это те, кто твоё Божество хотело бы видеть в идеале в гробу или вообще не на этом свете.
        Это такая же группа как и Почитатели, но жрец будет их убивать и отгонять, а не повелевать ими.</p>
    <hr>

    <p>Варианты ниже служат помощью для воображения при создании своего Божества и не являются строгой инструкцией.</p>

    <p><strong>Поклонение твоему Божеству</strong>…</p>
    <p>…древнее, распространено и широко известно</p>
    <p>…распространено в других местах</p>
    <p>…появилось недавно и многие о нём не слышали</p>
    <p>…старая вера, забытая многими</p>
    <p>…широко преследуемо</p>

    <p><strong>твойему Божеству поклоняются с помощью</strong>… (выбери одно или два)</p>
    <p>…торжественных гимнов</p>
    <p>…безмятежной медитации</p>
    <p>…аскетичного отречения</p>
    <p>…пылких танцев</p>
    <p>…официальных церемоний</p>
    <p>…наркотиков и дурманов</p>
    <p>…боли и жертвоприношений</p>

    <p><strong>В твоём родном городе, святилище твоего Божества…</strong></p>
    <p>…это самое уважаемое место, даже более известных или местных Божеств</p>
    <p>…недавно было восстановлено/создано, возможнотобой</p>
    <p>…видало лучшие деньки, это уж точно</p>
    <p>…скрыто от чужих глаз</p>

    <p><strong>Известная личность из твоей веры, возможно твой кумир или тот, на кого ты равняетесь…</strong> (выбери
        два или три)</p>
    <p>…жил давным давно, став легендой</p>
    <p>…был мучеником из-за своей веры</p>
    <p>…умер в стычке с врагами Божества или других сил</p>
    <p>…написал множество религиозных текстов</p>
    <p>…столкнулся с одним из Тех Кто Живёт Внизу</p>
    <p>…мирно умер в своей кровати</p>
    <p>…вознёсся к Божеству за свою верную службу</p>
    <p>…был перерождён&nbsp;— в виде тебя</p>

    <p><strong>Ты получили свои силы…</strong></p>
    <p>…после многих лет учения и приверженности</p>
    <p>…когда твой предшественник передал их тебе</p>
    <p>…неожиданно, в момент большой нужды</p>
    <p>…после видения от твоего Божества или визита его посланника</p>
    <p>…когда ты первый раз увидели ______________</p>


</section>

`