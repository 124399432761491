import React from 'react'

import DwMoveLink from '@/components/DwMoveLink'
import sortByName from '@/utils/sortByName'

export default [
  {
    id: 'noble.background.decadent',
    name: 'Декадентский дилетант',
    text: (
      <>
        <p>Ты жил в богатстве и роскоши.</p>
        <p><strong>У тебя <em>Владения</em> +2</strong>.</p>
        <p>Ты умеешь закатывать лучшие вечеринки. <strong>Закатывая <DwMoveLink id='common.carouse'>Пирушку</DwMoveLink></strong>,  бросай с преимуществом и добавь этот вариант в список 10+ <DwMoveLink id='common.carouse'>Пирушки</DwMoveLink>:</p>
        <ul>
          <li>Ты налаживаешь полезные связи — в следующий раз в этом поселении бросай <DwMoveLink id='noble.start.privilege'>Привилегированность</DwMoveLink> с преимуществом.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.background.last_scion',
    name: 'Последний отпрыск',
    text: (
      <>
        <p>Твоя (когда-то гордая) семья теперь переживает не лучшие времена.</p>
        <p><strong>У тебя <em>Владения</em> +1</strong>.</p>
        <p><strong>Ты можешь пользоваться <DwMoveLink id='noble.start.privilege'>Привилегированностью</DwMoveLink> с нечистыми на руку торгашами, бандитами и прочей криминальной шушерой</strong>.</p>
      </>
    ),
  },
  {
    id: 'noble.background.distant_relative',
    name: 'Дальний родственник',
    text: (
      <>
        <p>Ты стоишь настолько далеко в линии наследования, что должен заслужить свою славу сам, пускаясь во все тяжкие.</p>
        <p><strong>У тебя <em>Владения</em> +0</strong>.</p>
        <p><strong>Твоя кость урона теперь к8.</strong></p>
      </>
    ),
  },
  {
    id: 'noble.start.privilege',
    name: 'Привилегированность',
    text: (
      <>
        <p>Ты — отпрыск знатной семьи, и она имеет вес. Это отражено <em>Владениями</em>.</p>
        <p><strong>Чтобы добиться чего-то, используя семейные ресурсы и влияние среди людей, уважающих дворянство</strong>, брось +<em>Владения</em>.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— твой запрос будет исполнен, но выбери одно.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— твой запрос возможно выполнить, но выбери одно и Ведущий выберет одно.</p>
            <ul>
              <li>Ты будешь кому-то должен и не сможешь использовать этот ход снова, пока долг не будет погашен.</li>
              <li>Тебе придется довольствоваться чем-то близким к тому, что ты хотел.</li>
              <li>Ты привлечёшь к себе нежелательное внимание.</li>
              <li>У сделки будут какие-то ограничения или условия.</li>
            </ul>
          </li>
        </ul>
        <p>Чем больше твой запрос, тем больше будут непредвиденные проблемы.</p>
      </>
    ),
    note: (
      <p>Это не значит, что у тебя есть простой и лёгкий способ воспользоваться богатствами своей семьи, но ты можешь это организовать.</p>
    ),
  },
  {
    id: 'noble.start.education',
    name: 'Образование',
    text: (
      <>
        <p>Тебе дали великолепное образование. <strong>Выбери две темы, в которых ты лучше всего разбираешься:</strong></p>
        <ul>
          <li>История изведанного мира.</li>
          <li>Боги и те, кто им служит.</li>
          <li>География и политика.</li>
          <li>Языки и культура.</li>
          <li>Война и стратегия.</li>
          <li>Магия и легенды.</li>
        </ul>
        <p><strong><DwMoveLink id='common.spout_lore'>Копаясь в памяти</DwMoveLink> на эти темы</strong>, бросай с преимуществом. </p>
      </>
    ),
  },
  {
    id: 'noble.start.landlord',
    name: 'Землевладелец',
    text: (
      <>
        <p>Твоя семья владеет поместьем или чем-то подобным. Опиши его и расскажи, где оно на карте! <strong>Находясь в семейном поместье</strong>, бросай +<em>Владения</em> с преимуществом.</p>
        <p><strong>Выбери <em>Владения</em> + 1 особенностей поместья.</strong></p>
        <ul>
          <li>Верные слуги (назови их!)</li>
          <li>Знаменитая история (опиши её!)</li>
          <li>Известный обитатель. Назови его и расскажи: почему он так важен?</li>
          <li>Магическая лаборатория.</li>
          <li>Полезное место: <em>стратегическое</em>, <em>тайное </em>или <em>коммерческое</em>.</li>
          <li>Секретные проходы и катакомбы.</li>
          <li>Секретный вход или тоннель для побега.</li>
          <li>Укреплённые стены.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.round_table',
    name: 'Круглый стол',
    text: (
      <>
        <p><strong>Организовывая собрание для  обсуждения политики</strong>, брось +<em>Владения</em>.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— задай два вопроса.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— задай один вопрос.</p>
            <ul>
              <li>Что сейчас является самой большой угрозой для моего дома или для меня?</li>
              <li>Кто имеет влияние на / в ___________?</li>
              <li>Каковы отношения между ___________ и ____________?</li>
              <li>Могу ли я достаточно доверять __________, чтобы __________?</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.silver_tongue',
    name: 'Острый язык',
    text: (
      <>
        <p><strong>Злобно насмехаясь или издеваясь над персонажем Ведущего</strong>, брось +ХАР.</p>
        <ul>
          <li>
            <p>На <strong>10+</strong>&nbsp;— он либо бросается на тебя, либо теряет всякое уважение соратников. Ты также можешь выбрать одно:</p>
            <ul>
              <li>Ты получаешь преимущество на следущий ход против него.</li>
              <li>Он в ответ выкрикивает или как-то иначе раскрывает какой-то факт о себе: задай Ведущему один вопрос о цели издёвок и получи честный ответ.</li>
            </ul>
          </li>
          <li>На <strong>7–9</strong>&nbsp;— он либо бросается на тебя, либо теряет всякое уважение соратников.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.skilled_duelist',
    name: 'Элегантный дуэлянт',
    text: (
      <>
        <p><strong>Скрещивая клинки с неотёсанным громилой</strong>, брось +ХАР.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— выбери три.</li>
          <li>
            <p>На <strong>7–9</strong>&nbsp;— выбери одно.</p>
            <ul>
              <li>Ты ненадолго удерживаешь его клинок своим, полностью занимая его внимание.</li>
              <li>Ты сближаешься или отступаешь из ближнего боя с ним.</li>
              <li>Ты перемещаешь его на пару шагов, но не в явно опасное место.</li>
              <li>Ты избегаешь контратаки.
              .</li>
              <li>Нанеси урон с помехой.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.courtier',
    name: 'Придворный',
    text: (
      <>
        <p><strong>Встречаясь с кем-то для дипломатических переговоров</strong>, задай Ведущему два вопроса из списка ниже и получи честный ответ:</p>
        <ul>
          <li>Ждёт ли меня засада?</li>
          <li>Что он чувствует на <em>самом деле</em>?</li>
          <li>Каков мой лучший выход из этой ситуации?</li>
          <li>Кто наблюдает за этой встречей?</li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.matter_of_coins',
    name: 'Денежный вопрос',
    text: (
      <>
        <p><strong>Пытаясь убедить кого-либо, используя своё богатство или статус</strong>, брось +<em>Владения</em>.</p>
        <ul>
          <li>На <strong>10+</strong>&nbsp;— он под впечатлением и относится к тебе с почётом и уважением. Если ты убеждал целую группу, то завоёвываешь не всех, но большинство из них.</li>
          <li>На <strong>7–9</strong>&nbsp;— он как минимум радушен, но твои действия также разворошили что-то нехорошее.</li>
        </ul>
      </>
    ),
  },
  {
    id: 'noble.advanced.natural_leader',
    name: 'Прирождённый лидер',
    text: (
      <>
        <p>Когда ты <strong>повышаешь голос</strong>, тебя услышат даже посреди громкой битвы:</p>
        <p><strong>Вступая в битву</strong>, получи 2 <em>Командования</em>. Ты можешь потратить 1 <em>Командования</em>, чтобы выкрикнуть приказ или предупреждение союзнику; он получит преимущество на свой следующий бросок, если сделает так, как ты сказал.</p>
        <p><strong>Когда битва заканчивается</strong>, потеряй всё <em>Командование</em>.</p>
      </>
    ),
  },
  {
    id: 'noble.advanced.hero_blood',
    name: 'Кровь героев',
    text: (
      <>
        <p><em>Другие классы не могут взять этот ход.</em></p>
        <p>Твой дворянский род включает в себя известного героя, и ты унаследовал часть его талантов. Опиши что это за герой и как его таланты проявляются в тебе, а затем возьми ход другого класса (даже стартовый), к которому твой предок логично мог принадлежать.</p>
      </>
    ),
  },
  {
    id: 'noble.advanced.high_society_style',
    name: 'Стиль высшего света',
    text: (
      <>
        <p><strong>Взаимодействуя с кем-то</strong>, ты можешь спросить контролирующего его игрока, находит ли этот персонаж тебя привлекательным и получить честный ответ (обычно — <em>«Да»</em>).</p>
        <p><strong><DwMoveLink id='common.parley'>Договариваясь</DwMoveLink> с кем-то и используя своё очарование как рычаг воздействия</strong>, бросай с преимуществом.</p>
      </>
    ),
  },
  {
    id: 'noble.advanced.throw_gauntlet',
    name: 'Бросить перчатку',
    text: (
      <>
        <p><strong>Когда твоя честь оскорблена и ты требуешь дуэли</strong>, твой оппонент не может отказаться. Он может выдвигать условия или предложить замену на что-то другое, но отказаться он не может.</p>
        <p><strong>Когда дуэль начинается</strong>, получи преимущество на следующий ход.</p>
      </>
    ),
  },
  {
    id: 'noble.capstone.family_pride',
    name: 'Гордость рода',
    text: (
      <>
        <p>Увеличь свои <em>Владения</em> на 1. Опиши, как твои действия пошли на пользу богатству и известности семьи.</p>
      </>
    ),
  },
  {
    id: 'noble.capstone.focus_fire',
    name: 'Сфокусировать огонь',
    text: (
      <>
        <p><em>Требуется</em>: <DwMoveLink id='noble.advanced.natural_leader'>Прирождённый лидер</DwMoveLink></p>
        <p><strong>Когда ты приказываешь союзникам уничтожить врага</strong>, ты тратишь 1 <em>Командования</em> и каждый союзник получает преимущество на следующий бросок урона против этого врага.</p>
      </>
    ),
  },
]
  .map(
    move => ({
      ...move,
      classId: 'noble',
    }),
  )
  .sort(sortByName)
